const themeUiTheme = {
  colors: {
    text: "#131E26",
    background: "#ffffff",
    darkBackground: "#595959",
    lightBackground: "#fafafa",
    primary: "#E30000",
    secondary: "#E36256",
    light: "#ffffff",
    dark: "#131E26",
    lightGrey: "#999999",
  },
  fonts: {
    body:
      '"Euclid Square", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Euclid Square", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 384, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96, 128],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1640,
    small: 960,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "body",
      color: "primary",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "body",
      color: "dark",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "body",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "body",
      color: "dark",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "body",
      color: "dark",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "dark",
    },
    label: {
      fontSize: 0,
      color: "dark",
      textTransform: "uppercase",
      mb: 3,
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  box: {
    dark: {
      backgroundColor: "dark",
      color: "light",
    },
  },
  layout: {
    container: {
      paddingX: [3, 4],
      paddingY: [4, 4],
    },
    text: {
      paddingX: [3, 4],
      paddingTop: [6, 8],
      paddingBottom: [4, 6],
    },
    carousel: {
      paddingX: [3, 4],
      paddingY: [4, 5, 6, 6],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    lg: {
      maxWidth: "1200px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    button: {
      fontSize: [2],
      paddingX: [4],
      paddingY: [3],
      cursor: "pointer",
      color: "dark",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "primary",
      borderRadius: "8px",
      textDecoration: "none",
      "&:hover": {
        color: "light",
        backgroundColor: "dark",
      },
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
    breadcrumbs: {
      textDecoration: "none",
      color: "light",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#E30000",
      ".ReactModal__Overlay": {
        opacity: 0,
        transition: "opacity 300ms ease-in-out",
      },
      ".ReactModal__Overlay--after-open": {
        opacity: 1,
      },
      ".ReactModal__Overlay--before-close": {
        opacity: 0,
      },
    },
  },
}

export default themeUiTheme
